<template>
  <div>
    <route-buttons />
    <b-row class="match-height">
      <b-col
        cols="12"
        md="6"
      >
        <b-card :title="$t('Pazar Evresi')">
          <market-phase />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card>
          <market-graphic />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import marketPhase from '@/views/App/Reports/components/market_analysis/Market_phase.vue'
import marketGraphic from '@/views/App/Reports/components/market_analysis/Market_graphic.vue'
import routeButtons from '@/views/App/Reports/components/Route_buttons.vue'

export default {
  name: 'MarketAnalysis',
  components: {
    BCard,
    BRow,
    BCol,
    marketPhase,
    marketGraphic,
    routeButtons,
  },
  created() {
    this.getMarketAnalysis()
  },
  methods: {
    getMarketAnalysis() {
      this.$store.dispatch('marketAnalysisReport/marketAnalysis', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
